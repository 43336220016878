<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col>
        <template>
          <v-card elevation="0">
            <v-data-table
              :headers="TransitModelHeader"
              :items="allchalanArray"
              :search="searchchalan"
              :loading="loading"
              loading-text="Please wait..."
              item-key="id"
              v-model="selected"
              :single-select="singleSelect"
              show-select
              class="elevation-0"
              style="background: #f5f5f5"
              return-object
            >
              <template v-slot:top>
                <v-toolbar
                  elevation="2"
                  style="background: #f5f5f5; font-size: 26px"
                >
                  <v-toolbar-title
                    style="margin-left: -14px"
                    class="mt-2 mb-2 ml-2"
                  >
                    All Products
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pa-5">
                    <v-text-field
                      v-model="searchchalan"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <div class="pa-5">
                    <v-btn
                      color="primary"
                      @click="savetransit(item)"
                      :disabled="!selected.length"
                    >
                      Create Flash
                    </v-btn>
                  </div>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
              </template>
              <!-- <template
                v-slot:item.actionsqty="{ item }"
                style="max-width: 30px"
              >
                <v-text-field
                  v-model="item.requested_qty"
                  outlined
                  dense
                  class="mt-5"
                  style="max-width: 100px"
                ></v-text-field>
              </template> -->

              <!-- <template v-slot:item.admin_status="{ item }">
                <v-chip :color="getColor(item.admin_status)" dark>
                  {{ item.admin_status }}
                </v-chip>
              </template> -->
            </v-data-table>

            <v-dialog v-model="Transfersavedialog" max-width="1200px">
              <v-card>
                <v-data-table
                  :headers="FlashModelHeader"
                  :items="selected"
                  item-key="id"
                  v-model="selected"
                  :single-select="singleSelect"
                  show-select
                  class="elevation-1"
                  style="background: #f5f5f5"
                  return-object
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:top>
                    <v-toolbar
                      elevation="2"
                      style="background: #f5f5f5; font-size: 26px"
                      height="80"
                    >
                      <p class="mb-0">Confirm Flash Product</p>
                      <v-spacer></v-spacer>

                      <v-divider class="mx-4" inset vertical></v-divider>
                      <!-- <v-form ref="createflashform"
v-model="valid_createform"
>

</v-form> -->
                      <v-col cols="2" class="mt-10 mb-5">
                        <v-form v-model="startDateFormValid">
                          <v-datetime-picker
                            v-model="start_date"
                            prev-icon="mdi-skip-previous"
                            next-icon="mdi-skip-next"
                            class="mt-0"
                            color="purple darken-2"
                            dense
                            label="Start Time"
                            :rules="rules"
                            required
                          >
                          </v-datetime-picker>
                        </v-form>
                      </v-col>
                      <v-col cols="2" class="mt-10 mb-5">
                        <v-datetime-picker
                          v-model="end_date"
                          prev-icon="mdi-skip-previous"
                          next-icon="mdi-skip-next"
                          class="mt-0"
                          color="purple darken-2"
                          dense
                          label="End Time"
                        >
                        </v-datetime-picker>
                      </v-col>

                      <v-col cols="3">
                        <v-radio-group
                          v-model="option"
                          v-on:change="optionToggle()"
                          row
                          class="mt-10"
                        >
                          <v-radio label="Individual" value="Individual">
                          </v-radio>
                          <v-radio label="All" value="All"> </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-toolbar>
                    <div v-show="option == 'All'">
                      <v-card height="80" class="mb-10">
                        <v-form v-model="allValid">
                          <v-row>
                            <v-divider></v-divider>
                            <v-col cols="3">
                              <v-select
                                v-model="alldiscount_type"
                                :items="discount_typessingle"
                                outlined
                                dense
                                class="mt-5"
                                label="Discount Type"
                                :rules="rules"
                              ></v-select>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                v-model="allamount"
                                outlined
                                dense
                                class="mt-5 mr-10"
                                type="number"
                                label="Value"
                                :rules="rules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card>
                    </div>
                  </template>

                  <template
                    v-slot:item.distype="{ item }"
                    style="max-width: 30px"
                    v-if="option == 'Individual'"
                  >
                    <v-form v-model="individualValidFirst">
                      <v-select
                        v-model="item.discount_type"
                        :items="discount_types"
                        outlined
                        dense
                        class="mt-5"
                        style="max-width: 100px"
                        :rules="rules"
                        required
                      ></v-select>
                    </v-form>
                  </template>
                  <template
                    v-slot:item.disamount="{ item }"
                    style="max-width: 30px"
                    v-if="option == 'Individual'"
                  >
                    <v-form v-model="individualValidLast">
                      <v-text-field
                        v-model="item.amount"
                        outlined
                        dense
                        required
                        :rules="rules"
                        class="mt-5"
                        style="max-width: 100px"
                        type="number"
                      ></v-text-field>
                    </v-form>
                  </template>
                </v-data-table>
                <v-card-actions class="py-5">
                  <v-spacer></v-spacer>

                  <v-btn elevation="0" color="red" @click="CancelInvoice()">
                    Cancel
                  </v-btn>
                  <v-btn
                    @click="SaveApproveInvoice(item)"
                    elevation="0"
                    color="success"
                    :disabled="!allValid
                    || !startDateFormValid  || !individualValidFirst || !individualValidLast"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    show: true,
    fixed: true,
    fixedNumber: [1, 1],
    search: "",
    start_date: "",
    end_date: "",
    menu1: false,
    menu2: false,
    loading: true,
    amount: 0,
    option: "",
    allamount: 0,
    alldiscount_type: "",
    snackbar: false,
    color: "",
    text: "",
    // valid_createform:false,

    allValid: false,
    individualValidFirst: true,
    individualValidLast: true,
    startDateFormValid: false,
    rules: [(v) => !!v || "Field is required"],

    // ChalanHeader: [
    //   { text: "product ID", value: "product_id", sortable: false },
    //   { text: "Specification ID", value: "id", sortable: false },
    //   { text: "Title", value: "product_name" },
    //   { text: "Brand", value: "brand_name", sortable: false },
    //   { text: "Color", value: "product_color", sortable: false },
    //   { text: "Size", value: "product_size", sortable: false },
    //   { text: "quantity", value: "credit", sortable: false },
    //   { text: "Purchase ", value: "purchase_price", sortable: false },
    //   { text: "Selling", value: "selling_price", sortable: false },
    // ],
    TransitModelHeader: [
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "id", sortable: false },
      { text: "Title", value: "product_data.title" },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "quantity", value: "quantity", sortable: false },
      { text: "", value: "adquantity", sortable: false },

      // { text: "Selling Price", value: "old_price" },
      // { text: "Purchase Price", value: "review", sortable: false },
      // { text: "Selling Price", value: "comments", sortable: false },
    ],

    FlashModelHeader: [
      { text: "Spec ID", value: "id", sortable: false },
      { text: "Title", value: "product_data.title" },

      { text: "", value: "adquantity", sortable: false },

      // { text: "Selling Price", value: "old_price" },
      {
        text: "Purchase Price",
        value: "product_price.purchase_price",
        sortable: false,
      },
      { text: "Selling Price", value: "product_price.price", sortable: false },
      { text: "Discount Type", value: "distype", sortable: false },

      { text: "Amount", value: "disamount", sortable: false },
    ],

    expanded: [],
    singleExpand: false,

    discount_types: ["none", "amount", "percentage"],
    discount_typessingle: ["none", "percentage"],
    select: { state: "All Products" },

    searchchalan: "",
    AllWarehouseList: [],
    trnasferob: {},
    selected: [],
    Transfersavedialog: false,
    singleSelect: false,
    valid_add_discount: false,
    addDiscountDialog: false,
    specdataobjdiscount: {
      product_data: {},
    },

    menudist: false,
    menu2dist: false,
    addedwarehousequantity: [],
    addedshopquantity: [],
    quantityIndex: -1,
    quantityID: -1,
    allchalanArray: [],
    chalanproducts: [],
    allChalanobj: {},
    viewchalandialog: false,
    tempobj: {
      data: {},
    },
    searchitemArray: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    addquantitydialog: function (newValue) {
      this.addedwarehousequantity.length = 0;
      this.addedshopquantity.length = 0;

      // IF add quantity and price modal is closed then reset all the variable
      if (!newValue) {
        this.quantityIndex = -1;
        this.quantityID = -1;
        this.$refs.priceForm.reset();
      }
    },
  },

  methods: {
    optionToggle() {
      if (this.option == "All") {
        this.allValid = false;
        this.individualValidFirst = true;
        this.individualValidLast = true;
      } else {
        this.allValid = true;
        this.individualValidFirst = false;
        this.individualValidLast = false;
      }
    },

    TransferSelectInfo() {
      // console.log(this.trnasferob);
      axios.get("productdetails/getallSpecification/").then((response) => {
        if (response.data.success) {
          this.allchalanArray = response.data.data;
          this.loading = false;
        }
      });
    },

    SaveApproveInvoice(trnasferob) {
      if (this.option == "Individual") {
        this.selected.forEach((element) => {
          element.specification_id = element.id;
          element.product_id = element.product_id;
          element.admin_id = this.user.user_id;
          element.is_flash = true;
          element.start_date = this.start_date;
          element.end_date = this.end_date;
          element.amount = parseInt(element.amount);
        });
        // let datas = {
        //   user_id: this.user.user_id,
        //   transaction_info: this.selected,
        // };

        axios
          .post("product/create_flash_sale/", this.selected)
          .then((response) => {
            if (response.data.success) {
              this.text = response.data.message;

              this.color = "success";
              this.snackbar = true;
              this.Transfersavedialog = false;
              this.allchalanArray = this.allchalanArray.filter((element) => {
                this.selected.some((item) => item.id == element.id);
              });
              this.selected = [];
              this.TransferSelectInfo();
              this.viewchalandialog = true;
              this.end_date = "";
              this.start_date = "";
            } else {
              this.text = response.data.message;
              this.color = "error";
              this.snackbar = true;
            }
          });
      } else {
        this.selected.forEach((element) => {
          element.specification_id = element.id;
          element.product_id = element.product_id;
          element.admin_id = this.user.user_id;
          element.is_flash = true;
          element.start_date = this.start_date;
          element.end_date = this.end_date;
          element.amount = parseInt(this.allamount);
          element.discount_type = this.alldiscount_type;
        });
        // let datas = {
        //   user_id: this.user.user_id,
        //   transaction_info: this.selected,
        // };

        // console.log(this.selected);
        axios
          .post("product/create_flash_sale/", this.selected)
          .then((response) => {
            if (response.data.success) {
              this.text = response.data.message;

              this.color = "success";
              this.snackbar = true;
              this.Transfersavedialog = false;
              this.allchalanArray = this.allchalanArray.filter((element) => {
                this.selected.some((item) => item.id == element.id);
              });
              this.selected = [];
              this.TransferSelectInfo();
              this.viewchalandialog = true;
              this.end_date = "";
              this.start_date = "";
            } else {
              this.text = response.data.message;
              this.color = "error";
              this.snackbar = true;
            }
          });
      }
    },
    savetransit(selected) {
      // console.log("this is ", this.selected);
      this.Transfersavedialog = true;
    },
    CancelInvoice() {
      this.Transfersavedialog = false;
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    this.TransferSelectInfo();
  },
};
</script>


<style>
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

